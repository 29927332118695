import { useParams } from 'react-router-dom';
import {
  Heading,
  Text,
  Flex,
  useTheme,
  Icon,
  Button
} from '@chakra-ui/react';
import { createContext, useState } from 'react';
import { useAuth } from '@contexts/auth_context';
import useSWR from 'swr';
import { FullScreenSpinner } from '@/components/FullScreenSpinner';
import { ErrorPage } from '@/components/ErrorPage';

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { domainUrl } from '@/utils/fetch_utils';
import styles from "./styles.module.scss"
import { IssueTag } from '@/components/IssueTag';
import { ApproverRoleMappings, ICreateIssueCommentParams, IIssue, IIssueActivityItem, IUpdateIssueParams, IssueApproverRole, IssueStage, StageTagMappings, convertActivityItemFromServer, convertIssueFromServer } from '@/types/issue';
import { FaClock } from 'react-icons/fa6';
import { PhotoList } from '@/components/PhotoList';
import { IPhoto } from '@/components/PhotoList/photo_list';
import { IssueActivity } from '@/components/IssueActivity';
import { IssueEditModal } from '@/components/IssueEditModal';
import { useTimezone } from '@contexts/timezone_context';

interface IIssueDetailContext {
  issueId: number;
  issue?: IIssue | null;
  activity?: Array<IIssueActivityItem>;
  setLightboxIndex: (index: number) => void;
  setSlides: (photos: Array<IPhoto>) => void;
  setActivity: (activity: Array<IIssueActivityItem>) => void;
  addComment: (issueCommentParams: ICreateIssueCommentParams) => void;
}

export const IssueDetailContext = createContext<IIssueDetailContext>({
  issueId: 0,
  issue: null,
  activity: [],
  setLightboxIndex: (index: number) => {},
  setSlides: (photos: Array<IPhoto>) => {},
  setActivity: (activity: Array<IIssueActivityItem>) => {},
  addComment: (issueCommentParams: ICreateIssueCommentParams) => {},
});

const IssueDetailPage = () => {
  const { issue_id } = useParams();
  const authContext = useAuth();
  const theme = useTheme();
  const timezoneContext = useTimezone();

  const [lightboxIndex, setLightboxIndex] = useState<number>(-1);
  const [slides, setSlides] = useState<Array<IPhoto>>([]);
  const [editIssueModalOpen, setEditIssueModalOpen] = useState<boolean>(false);

  const fetcher = (url: string) => authContext.authenticatedFetch(url).then(res => res.json());

  const { data, error, isLoading } = useSWR(
    issue_id ? `${domainUrl}/issues/${issue_id}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const { data: activityData, error: activityError, isLoading: activityIsLoading } = useSWR(
    issue_id ? `${domainUrl}/issues/${issue_id}/activity` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const { data: locationsData, error: locationsError, isLoading: locationsIsLoading } = useSWR(
    `${domainUrl}/locations/user_suggestion_by_location`,
    fetcher
  );

  const issue = data ? convertIssueFromServer(data.issue, data.locations, data.users) : null;
  const activity = activityData ? activityData.activity.map((activityItem: any) => convertActivityItemFromServer(activityItem, activityData.users)) : [];

  const locations = locationsData ? Object.values(locationsData.locations).sort((a: any, b: any) => a.name.localeCompare(b.name)) : [];
  const users = locationsData ? Object.keys(locationsData.users).map((userId) => ({ ...(locationsData.users[userId]), id: userId })) : [];
  const suggestedUsersByLocationId = locationsData ? Object.keys(locationsData.userSuggestions).reduce((result: any, key) => {
    const userIds = locationsData.userSuggestions[key];
    result[key] = userIds.map((userId: string | number) => ({ ...(locationsData.users[userId]), id: userId }));
    return result;
  }, {}) : {};

  const addComment = async (issueCommentParams: ICreateIssueCommentParams) => {
    const formData = new FormData();

    try {
      (issueCommentParams?.attachments || []).forEach((photo, index) => {
        formData.append(`attachments[${index}]`, photo);
      });

      formData.append("text", issueCommentParams.text);

      const response = await authContext.authenticatedFetch(`${domainUrl}/issues/${issue_id}/issue_comments`, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const statusCode = response.status;
      const json = await response.json();

      if (json.error) {
        return json.error;
      }
      if (statusCode !== 200) {
        return "Unable to resolve issue. Please check your network connection and try again.";
      }

      return json.activity.map((activity: any)=> convertActivityItemFromServer(activity, json.users));
    } catch (error) {
      console.error(error);
      return -1;
    }
  };

  const updateIssue = async (issueParams: IUpdateIssueParams) => {
    const formData = new FormData();

    try {
      (issueParams?.attachmentsToUpload || []).forEach((photo, index) => {
        formData.append(`attachmentsToUpload[${index}]`, photo);
      });

      (issueParams?.attachments|| []).forEach((item, index) => {
        formData.append(`attachments[${index}]`, JSON.stringify(item));
      });

      if (issueParams.currentStage) formData.append("issue[currentStage]", issueParams.currentStage);
      if (issueParams.locationId) formData.append("issue[locationId]", issueParams.locationId.toString());
      if (issueParams.assigneeId) formData.append("issue[assigneeId]", issueParams.assigneeId.toString());
      if (issueParams.title) formData.append("issue[title]", issueParams.title);
      if (issueParams.dueDate) formData.append("issue[dueDate]", issueParams.dueDate.toJSON());
      if (issueParams.notesForCleaner) formData.append("issue[notesForCleaner]", issueParams.notesForCleaner);
      if (issueParams.approverRole) formData.append("issue[approverRole]", issueParams.approverRole);

      const response = await authContext.authenticatedFetch(`${domainUrl}/issues/${issue_id}`, {
        method: "put",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const statusCode = response.status;
      const json = await response.json();

      if (json.error) {
        return json.error;
      }
      if (statusCode !== 200) {
        return "Unable to resolve issue. Please check your network connection and try again.";
      }

      return convertIssueFromServer(json.issue, json.locations, json.users);
    } catch (error) {
      console.error(error);
      return -1;
    }
  };

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (data?.error) {
    return <ErrorPage errorMessage={data.error} />;
  }

  // TODO (connor): refactor this
  const renderDueDate = () => {
    const currentDate = new Date();
    let color = null;

    if (issue?.dueDate) {
      if (issue.currentStage !== IssueStage.CLOSED) {
        if (timezoneContext.isSameDay(issue.dueDate, currentDate)) {
          color = "secondary.orange";
        } else if (issue.dueDate < currentDate) {
          color = "secondary.red";
        }
      }

      return (
        <Flex flexDirection="row" alignItems="center">
          { color ? <Icon style={{ height: 16, width: 16, marginRight: 4 }} as={FaClock} color={color} /> : null }
          <Text>{timezoneContext.formatDate(issue?.dueDate, "MMM d, yyyy")}</Text>
        </Flex>
      );
    }

    return null;
  }

  const GeneralInfoSection = (): JSX.Element => {
    return (
      <Flex className={styles.sectionContainer} flexDirection="column">
        <Text className={styles.sectionHeader}>General Info</Text>

        <Flex
          borderRadius="12"
          flex={1}
          backgroundColor="white"
          border={`1px solid ${theme.colors.gray[200]}`}
          flexDirection="row"
          justifyContent="space-between"
          mt={0}
          p={6}
        >
          <div>
            <Text textColor="secondary.darkGray" className={styles.generalInfoLabel}>Due Date</Text>
            { renderDueDate() }
          </div>

          <div>
            <Text textColor="secondary.darkGray" className={styles.generalInfoLabel}>Building</Text>
            <div>{ issue?.location?.name }</div>
          </div>

          <div>
            <Text textColor="secondary.darkGray" className={styles.generalInfoLabel}>Stage</Text>
            <IssueTag text={StageTagMappings[issue?.currentStage || '']?.label} textColor={StageTagMappings[issue?.currentStage || '']?.textColor} backgroundColor={StageTagMappings[issue?.currentStage || '']?.backgroundColor} />
          </div>

          <div>
            <Text textColor="secondary.darkGray" className={styles.generalInfoLabel}>Assigned To</Text>
            <div>{ issue?.assignee?.name }</div>
          </div>

          <div>
            <Text textColor="secondary.darkGray" className={styles.generalInfoLabel}>Created At</Text>
            <div>{issue?.createdAt ? timezoneContext.formatDate(issue.createdAt, "MMM d, yyyy") : ''}</div>
          </div>

          <div>
            <Text textColor="secondary.darkGray" className={styles.generalInfoLabel}>Created By</Text>
            <div>{ issue?.creator?.name }</div>
          </div>

          { authContext.state?.features?.newIssueTrackingWorkflow ? (
            <div>
              <Text textColor="secondary.darkGray" className={styles.generalInfoLabel}>Approve & Close</Text>
              <div>{ ApproverRoleMappings[issue?.approverRole || IssueApproverRole.CREATOR].label }</div>
            </div>
            ) : null }
        </Flex>
      </Flex>
    );
  };

  const NotesForCleanerSection = (): JSX.Element => {
    return (
      <Flex className={styles.sectionContainer} flexDirection="column">
        <Text className={styles.sectionHeader}>Notes For Cleaner</Text>

        <Flex
          borderRadius="12"
          flex={1}
          backgroundColor="white"
          border={`1px solid ${theme.colors.gray[200]}`}
          flexDirection="column"
          mt={0}
          p={6}
        >
          { issue?.notesForCleaner ? (
            issue?.notesForCleaner?.split(/\\n|\n/g).map((line, index) => (
              <Flex key={index}>
                <Text mt={4} style={{ whiteSpace: "pre-line" }}>{line}</Text>
                <br />
              </ Flex>
            ))
          ) : null }
          <PhotoList
            urls={issue?.attachments?.map((attachment) => attachment.url) || []}
            onClickPhoto={(photos, photo) => {
              setSlides(photos);
              setLightboxIndex(photo.photoIndex);
            }} />
        </Flex>
      </Flex>
    );
  }

  return (
    <IssueDetailContext.Provider value={{
      issueId: Number(issue_id),
      issue: issue,
      activity: activity,
      setSlides: setSlides,
      setLightboxIndex: setLightboxIndex,
      setActivity: (newActivity: Array<IIssueActivityItem>) => {},
      addComment: addComment,
    }}>

      <Lightbox
        index={lightboxIndex}
        slides={slides}
        open={lightboxIndex >= 0}
        close={() => setLightboxIndex(-1)}
        plugins={[Zoom]}
      />

      <Flex flexDirection="column" >
        <Flex
          flexDirection="column"
          px={6}
          ml="1px" // So we don't cover up the shadow of the sidebar. Needed this because I couldn't get z-index to work
          backgroundColor="secondary.lightGray"
          zIndex={1}
        >
          <Flex
            flexDirection="column"
            backgroundColor="secondary.lightGray"
            zIndex={1}
            position="sticky"
            top="0"
          >
            <Heading
              as="h1"
              size="lg"
              pt={8}
              pb={6}
              marginBottom={0}
            >
              <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
                <Text noOfLines={[1, 2, 3]}>{issue?.title}</Text>
                <Button onClick={() => setEditIssueModalOpen(true)} style={{ minWidth: 95 }}>Edit Issue</Button>
              </Flex>
            </Heading>

            <GeneralInfoSection />
          </Flex>


          <NotesForCleanerSection />

          <IssueActivity />
        </Flex>

        <IssueEditModal isOpen={editIssueModalOpen} issue={issue} locations={locations} usersByLocationId={suggestedUsersByLocationId} allUsers={users} close={() => setEditIssueModalOpen(false)} onSave={updateIssue} />
      </Flex>
    </IssueDetailContext.Provider>
  );
};

export default IssueDetailPage;
