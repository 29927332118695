import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  useToast,
  FormLabel,
  FormControl,
  Textarea,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import styles from "./styles.module.scss";
import { DateTimePicker } from '../DateTimePicker';
import { PhotoList } from '../PhotoList';
import { useDropzone } from 'react-dropzone';
import { ApproverRoleMappings, DEPRECATED_STAGES, ICreateIssueParams, IIssue, IUpdateIssueParams, IssueApproverRole, IssueStage, OUTDATED_STAGE_LIFECYCLE, StageTagMappings } from '@/types/issue';
import { useAuth } from '@/contexts/auth_context';

const NO_SELECTION_OPTION = '';

interface IIssueEditModalProps {
  isOpen: boolean;
  issue: IIssue | null;
  locations: Array<any>;
  allUsers: Array<any>;
  usersByLocationId: { [key: number]: Array<any> };
  close: () => void;
  onSave: (issue: ICreateIssueParams | IUpdateIssueParams) => void;
}

const IssueEditModal = (props: IIssueEditModalProps) => {
  const { isOpen, issue, locations, usersByLocationId, allUsers, close, onSave } = props;
  if (!isOpen) {
    return null;
  }

  const authContext = useAuth();
  const toast = useToast();

  const stageOptions = authContext.state?.features?.newIssueTrackingWorkflow ? Object.values(IssueStage).filter(stage => !DEPRECATED_STAGES.includes(stage)) : OUTDATED_STAGE_LIFECYCLE;

  const isEdit = issue !== null;
  const originalIssue = issue;
  const [updatedIssue, setUpdatedIssue] = useState<IUpdateIssueParams | null | IIssue>(issue);

  // These are already uplaoded to S3 in our system
  const [attachments, setAttachments] = useState<Array<{ url: string, filename: string }>>(issue?.attachments || []);
  const [photosToUpload, setPhotosToUpload] = useState<Array<any>>([]);

  const modalTitle = isEdit ? "Edit issue" : "Create new issue";

  const hasChanges = () => {
    return (JSON.stringify(originalIssue) !== JSON.stringify(updatedIssue)) ||
      (attachments?.length !== originalIssue?.attachments?.length) ||
      photosToUpload.length > 0
  }

  const getValidationResult = () => {
    if (!updatedIssue?.title) {
      return "Please fill in the title.";
    }
    if (!updatedIssue?.dueDate) {
      return "Please select a due date.";
    }
    if (!updatedIssue?.locationId) {
      return "Please select a building.";
    }

    return null;
  }

  const validateInput = () => {
    toast.closeAll()
    const validationError = getValidationResult();
    if (validationError) {
      toast({
        description: validationError,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
    return true;
  }

  const handleDrop = (acceptedFiles: Array<any>) => {
    setPhotosToUpload((currentFiles) => [...currentFiles, ...acceptedFiles]);
    setAttachments((currentAttachments) => [...currentAttachments, ...(acceptedFiles.map((file) => ({ url: URL.createObjectURL(file), filename: file.name })))])
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: {
      'image/*': []
    },
  });

  const SelectLocation = (): JSX.Element => {
    const location = updatedIssue?.locationId ? locations[updatedIssue?.locationId] : null;

    return (
      <>
        <FormControl className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Building</FormLabel>
          <Select
            size='md'
            placeholder='Select building'
            onChange={(event) => setUpdatedIssue({...updatedIssue, locationId: parseInt(event.target.value)})}
            value={updatedIssue?.locationId || NO_SELECTION_OPTION}>
            {
              Object.values(locations).sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })).map((location) => {
                return <option key={location.id} value={location.id}>{location.name}</option>;
              })
            }
          </Select>
        </FormControl>
      </>
    )
  }

  const SelectAssignee = (): JSX.Element => {
    const assignee = updatedIssue?.assigneeId ? allUsers[updatedIssue?.assigneeId] : null;
    let userList = allUsers;

    if (updatedIssue?.locationId) {
      userList = Object.values(usersByLocationId[updatedIssue?.locationId] || {});
    }

    return (
      <>
        <FormControl className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Assigned To</FormLabel>
          <Select
            size='md'
            placeholder='Select the user'
            onChange={(event) => setUpdatedIssue({...updatedIssue, assigneeId: parseInt(event.target.value)})}
            value={updatedIssue?.assigneeId || NO_SELECTION_OPTION}>
            {
              [...new Set(userList)].map((user) => {
                return <option key={user.id} value={user.id}>{user.name}</option>;
              })
            }
          </Select>
        </FormControl>
      </>
    )
  }

  const SelectCurrentStage = (): JSX.Element => {
    return (
      <>
        <FormControl className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Current Stage</FormLabel>
          <Select
            size='md'
            placeholder='Select stage'
            onChange={(event) => setUpdatedIssue({...updatedIssue, currentStage: (event.target.value as IssueStage)})}
            value={updatedIssue?.currentStage || NO_SELECTION_OPTION}>
            {
              stageOptions.map((stage) => {
                return <option key={stage} value={stage}>{StageTagMappings[stage].label}</option>;
              })
            }
          </Select>
        </FormControl>
      </>
    )
  }

  const SelectApproverRole = (): JSX.Element => {
    return (
      <>
        <FormControl className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Who will approve & close?</FormLabel>
          <Select
            size='md'
            placeholder='Select approve & close'
            onChange={(event) => setUpdatedIssue({...updatedIssue, approverRole: (event.target.value as IssueApproverRole)})}
            value={updatedIssue?.approverRole || IssueApproverRole.CREATOR}>
            {
              Object.values(IssueApproverRole).map((stage) => {
                return <option key={stage} value={stage}>{ApproverRoleMappings[stage].label}</option>;
              })
            }
          </Select>
        </FormControl>
      </>
    )
  }

  const ModalButtons = (): JSX.Element => {
    if (isEdit) {
      return (
        <ButtonGroup
          justifyContent={"space-between"}
          width={"100%"}
          spacing={4}
        >
          <Button
            flex={1}
            isDisabled={!hasChanges()}
            variant='solid'
            onClick={() => {
              if(validateInput()) {
                const newIssue = { ...updatedIssue, attachmentsToUpload: photosToUpload, attachments: attachments }
                onSave(newIssue as IUpdateIssueParams);
                close();
              }
            }}>
            Save changes
          </Button>
        </ButtonGroup>
      )
    }
    return (
      <ButtonGroup
        justifyContent={"space-between"}
        width={"100%"}
        spacing={4}
      >
        <Button
          flex={1}
          variant='solid'
          isDisabled={!hasChanges()}
          onClick={() => {
            if(validateInput()) {
              const newIssue = { ...updatedIssue, attachmentsToUpload: photosToUpload, attachments: attachments }
              onSave(newIssue as ICreateIssueParams);
              close();
            }
          }}>
            Save
        </Button>
      </ButtonGroup>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={close} size={"xl"} isCentered>
        <ModalOverlay/>
        <ModalContent maxH="90vh">
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody
           overflowY="auto"
          >
            <FormControl style={{ marginBottom: 9 }}>
              <FormLabel>Issue</FormLabel>
              <Textarea
                placeholder="Type name of the issue"
                width="100%"
                size="md"
                minHeight="40px"
                value={updatedIssue?.title}
                onChange={(event) => setUpdatedIssue({...updatedIssue, title: event.target.value})}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Due Date</FormLabel>
              <DateTimePicker
                value={updatedIssue?.dueDate}
                onChange={(date: Date) => setUpdatedIssue({...updatedIssue, dueDate: date})}
                popperPlacement="bottom-start"
                showTime={false}
                showDate={true}
                alignment="left"
              />
            </FormControl>

            { isEdit ? <SelectCurrentStage /> : null }

            <SelectLocation />
            <SelectAssignee />
            { authContext.state?.features?.newIssueTrackingWorkflow ? <SelectApproverRole /> : null }

            <FormControl style={{ marginBottom: 9 }}>
              <FormLabel>Attach photos</FormLabel>

              <PhotoList
                urls={attachments?.map((attachment) => attachment.url)}
                canEdit
                photoSelectorTrigger={open}
                size={80}
                onRemove={(photoToRemove: string, index: number) => {
                  const attachmentsFilenamesToRemove = attachments.filter(item => item.url === photoToRemove).map((item) => item.filename);
                  setAttachments(currentAttachments => currentAttachments.filter((item, idx) => idx !== index));

                  const indexToRemove = photosToUpload.findIndex(file => attachmentsFilenamesToRemove.includes(file.name));
                  setPhotosToUpload(currentPhotosToUpload => currentPhotosToUpload.filter((item, idx) => idx !== indexToRemove));
                }}
              />
              {/* the input needs to be rendered here to work on Firefox & Safari - https://github.com/react-dropzone/react-dropzone/issues/1294#issuecomment-2173092399 */}
              <input type="file" {...getInputProps()} style={{ display: "none" }} />
            </FormControl>

            <FormControl>
              <FormLabel>Notes for Cleaner</FormLabel>
              <Textarea
                placeholder="Start to type"
                width="100%"
                size="md"
                value={updatedIssue?.notesForCleaner}
                onChange={(event) => setUpdatedIssue({...updatedIssue, notesForCleaner: event.target.value})}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <ModalButtons />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default IssueEditModal;
