import { Box, Center, Circle, Divider, Flex, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Text, Tooltip, useTheme } from "@chakra-ui/react";
import { BsExclamationCircleFill } from "react-icons/bs";

function getScoreColor(score) {
  const theme = useTheme();

  if (score == null || score == undefined) {
    return theme.colors.secondary.gray;
  }

  if (score < 0.65) {
    return theme.colors.secondary.red;
  }
  if (score < 0.80) {
    return theme.colors.secondary.yellow;
  }

  return theme.colors.secondary.green;
}

function getScoreDescription(category, locationData) {
  const scoreDescriptions = {
    inspections: (`The most recent Inspection score was `
      + `${Math.round(locationData?.inspectionScore * 100)}.`),
    attendance: (`The number of clocked hours was on average `
      + `${locationData.clockedTime > locationData.budgetedTime ? "greater" : "lower"} `
      + `than budgeted by ${Math.abs(1 - (locationData.clockedTime / locationData.budgetedTime)).toLocaleString(undefined, { style: "percent", minimumFractionDigits: 0 })} `
      + `over the last 90 days.`),
    issueTracking: `There were ${locationData?.issueCounts?.late} issues completed past the due date over the last 90 days.`,
  }

  return scoreDescriptions[category]
}

const HealthScoreSidebar = ({ location }) => {
  const theme = useTheme();
  const overallColor = getScoreColor(location.healthScores.overall)
  const scoreTooltips = {
    inspections: "The inspection health score is calculated by taking the weighted average of your inspection results over the last 90 days.",
    attendance: "The attendance health score is calculated by comparing the budgeted hours versus the actual hours that were clocked over the last 90 days.",
    issueTracking: "The issue tracking health score is calculated by looking at the number of issues that were completed past the due date compared to the total number of issues created over the last 90 days.",
  }

  return (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.25em"}
      width={"100%"}
    >
      <Flex flexDirection={"column"}>
        <Flex flexDirection={"row"} marginBottom={"1.5em"}>
          <Circle
            backgroundColor={`${overallColor}26`}
            size={"5.75em"}
          >
            <Circle
              backgroundColor={overallColor}
              size={"4.5em"}
            >
              <Text
                color={"white"}
                fontSize={"3xl"}
                fontWeight={"bold"}
              >
                {location.healthScores.overall ? Math.round(location.healthScores.overall * 100) : "N/A"}
              </Text>
            </Circle>
          </Circle>
          <Center paddingLeft={"1.25em"}>
            <Text
              fontSize={"2xl"}
              fontWeight={"medium"}
            >
              Building<br />Health Score
            </Text>
          </Center>
        </Flex>
        <Divider borderWidth={1} marginBottom={"0.5em"} />
        {Object.entries(location.healthScores).map(([category, score]) => {
          if (category == "overall") { return };
          const sectionColor = getScoreColor(score)
          return (
            <Box
              key={category}
              backgroundColor={theme.colors.secondary.lightGray}
              borderRadius={"1em"}
              marginTop={"0.75em"}
              padding={"1em"}
              width={"100%"}
            >
              <Flex flexDirection={"column"}>
                <Flex
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  marginBottom={"0.5em"}
                >
                  <Flex flexDirection={"row"} alignItems={"center"}>
                    <Text
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      textTransform={"capitalize"}
                      marginRight={"0.25em"}
                      marginBottom={0}
                    >
                      {
                        // Separate words with a space
                        category.replace(/([A-Z])/g, " $1")
                      }
                    </Text>
                    <Popover placement="top" trigger={"hover"}>
                      <PopoverTrigger>
                        <span>
                          <BsExclamationCircleFill color={theme.colors.secondary.darkGray} />
                        </span>
                      </PopoverTrigger>
                      <PopoverContent
                        border={"none"}
                        borderRadius={"0.5em"}
                        boxShadow={"0px 5px 10px 0px rgba(0, 0, 0, 0.1);"}
                      >
                        <PopoverArrow />
                        <PopoverBody>{scoreTooltips[category]}</PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                  <Circle
                    backgroundColor={"white"}
                    border={`1px solid ${sectionColor}`}
                    size={"2.25em"}
                  >
                    <Text
                      fontWeight={"bold"}
                    >
                      {Math.round(score * 100)}
                    </Text>
                  </Circle>
                </Flex>
                <Box
                  height={"6px"}
                  borderRadius={"12px"}
                  backgroundColor={"white"}
                  width={"100%"}
                  marginBottom={"1em"}
                >
                  <Box
                    height={"100%"}
                    width={`${score * 100}%`}
                    borderRadius={"12px"}
                    backgroundColor={sectionColor}
                  />
                </Box>
                <Text color={theme.colors.secondary.deepGray}>
                  {getScoreDescription(category, location)}
                </Text>
              </Flex>
            </Box>
          )
        })
        }
      </Flex>
    </Box>
  )
}

export default HealthScoreSidebar;
