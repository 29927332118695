import { useAuth } from "@contexts/auth_context";
import {
  Heading, Divider,
  Flex, Spacer,
  ButtonGroup, Button,
} from "@chakra-ui/react";

import styles from "./styles.module.scss";
import {
  addDays, subDays,
  format, set, compareAsc, differenceInMinutes
} from "date-fns";
import { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  TagLabel,
  Checkbox,
} from '@chakra-ui/react';
import {AiFillLeftCircle, AiFillRightCircle} from "react-icons/ai";
import { FullScreenSpinner } from '@/components/FullScreenSpinner';
import { ErrorPage } from '@/components/ErrorPage';
import useSWR from 'swr';

const numberOfDays = 7;
const getUrl = (inputDate: Date) => {
  const startDatetime = subDays(inputDate, numberOfDays).toISOString();
  const endDatetime = inputDate.toISOString();
  const domainUrl = import.meta.env.VITE_API_SERVER;

  return `${domainUrl}/calendar_events?start_datetime=${encodeURIComponent(startDatetime)}&end_datetime=${encodeURIComponent(endDatetime)}&sort_by=status`;
}

interface CalendarEvent {
  assignmentId: string;
  assignedUserId: string;
  supervisorId: string;
  locationId: string;
  status: string;
  shifts: Array<{
    id: string;
    clockInTimestamp?: string;
    clockOutTimestamp?: string;
  }>;
  scheduledStartDatetime: string;
  duration: string;
  detached?: boolean;
}

const computeBuildingStatus = (inputDate, assignments) => {
  const statuses = [];
  if (!assignments) {
    assignments = [];
  }
  assignments = assignments.filter((assignment) => assignment.status !== "upcoming");
  for(let i = numberOfDays; i > 0; i--) {
    const today = subDays(inputDate, i);
    const nextDay = subDays(inputDate, i - 1);
    const assignmentsForDay = assignments.filter((assignment) => {
      const eventStartTime = new Date(assignment.eventInfo.calendarTime)
      return compareAsc(eventStartTime, today) >= 0 && compareAsc(eventStartTime, nextDay) < 0;
    });
    const statusForDay = computeBuildingStatusForSingleDay(assignmentsForDay);
    statuses.push({date: today, status: statusForDay, assignments: assignmentsForDay});
  }
  return statuses;
}

const countShifts = (assignments) => {
  const missedShifts = assignments.filter((assignment) => assignment.status !== "upcoming" && assignment.status !== "completed" && assignment.status !== "not_tracked" && assignment.status !== "clocked_in").length;
  const notTrackedShifts = assignments.filter((assignment) => assignment.status === "not_tracked").length;
  const totalTrackedShifts = assignments.filter((assignment) => assignment.status !== "upcoming" && assignment.status !== "not_tracked").length;
  const missedClockOut = assignments.filter((assignment) => assignment.status === "clocked_in").length;
  return {missedShifts, missedClockOut, notTrackedShifts, totalTrackedShifts}
}

const countHours = (assignments) => {
  let scheduledHours = 0;
  let completedHours = 0;
  assignments.forEach((event) => {
    const eventInfo = event.eventInfo;
    const shift = event.clockedShift;
    if (event.status === 'completed') {
      completedHours += differenceInMinutes(new Date(shift.clockOutTimestamp), new Date(shift.clockInTimestamp)) / 60;
    }
    if(event.status !== 'not_tracked' && event.status !== 'upcoming') {
      scheduledHours += eventInfo.duration / 60 / 60;
    }
  });

  return { completedHours: Math.round(completedHours * 2) / 2, scheduledHours: Math.round(scheduledHours * 2) / 2}
}

const computeBuildingStatusForSingleDay = (assignments) => {
  if (assignments.length === 0) {
    return "N/A";
  }
  if (assignments.some((assignment) => assignment.status === "no_show")) {
    return "no_show";
  }
  if (assignments.some((assignment) => assignment.status === "clocked_in")) {
    return "clock_in_only";
  }
  if (assignments.some((assignment) => assignment.status === "not_tracked")) {
    return "not_tracked";
  }
  if (assignments.every((assignment) => assignment.status === "completed")) {
    return "completed";
  }
  return "unknown";
}

const getStatusPill = (status) => {
  switch (status) {
    case "completed":
      return (
        <Tag size="sm" colorScheme="green" borderRadius="full">
          <TagLabel>completed</TagLabel>
        </Tag>
      );
    case "no_show":
      return (
        <Tag size="sm" colorScheme="red" borderRadius="full">
          <TagLabel>no show</TagLabel>
        </Tag>
      );
    case "clock_in_only":
      return (
        <Tag size="sm" colorScheme="orange" borderRadius="full">
          <TagLabel>clock-in only</TagLabel>
        </Tag>
      );
    case "not_tracked":
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>not tracked</TagLabel>
        </Tag>
      );
    default:
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>{status}</TagLabel>
        </Tag>
      );
  }
}

const BuildingReport = () => {
  const authContext = useAuth();
  const [startDatetime, setStartDatetime] = useState<Date>(set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}));
  const [showFewerColumns, setShowFewerColumns] = useState(false);

  const { data, error, isLoading } = useSWR(
    getUrl(startDatetime),
    async (url) => {
      const response = await authContext.authenticatedFetch(url);
      return response.json();
    }
  );

  const shiftDateBackwards = () => {
    // Clear all events first otherwise the page will flicker
    setStartDatetime(subDays(startDatetime, 1));
  }

  const shiftDateForwards = () => {
    // Clear all events first otherwise the page will flicker
    setStartDatetime(addDays(startDatetime, 1));
  }

  const resetDateToToday = () => {
    // Clear all events first otherwise the page will flicker
    setStartDatetime(set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}));
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  const { calendarEvents, users, locations } = data;

  // Convert calendar events to the building-employee format
  const assignmentsByBuildingEmployee: { [key: string]: CalendarEvent[] } = {};
  const allBuildingEmployee = new Set<string>();

  Object.values(calendarEvents).forEach((event: CalendarEvent) => {
    const location = locations[event.locationId];
    const janitor = users[event.assignedUserId];
    const key = `${location.name} - ${janitor.name}`;

    if (!assignmentsByBuildingEmployee[key]) {
      assignmentsByBuildingEmployee[key] = [];
    }

    const convertedEvent = {
      ...event,
      location: locations[event.locationId],
      janitor: users[event.assignedUserId],
      eventInfo: {
        calendarTime: event.scheduledStartDatetime,
        duration: event.duration,
      },
      clockedShift: event.shifts[0],
    };

    assignmentsByBuildingEmployee[key].push(convertedEvent);
    allBuildingEmployee.add(key);
  });

  const buildingStatus: { [key: string]: Array<{date: Date, status: string, assignments: any[]}> } = {};
  allBuildingEmployee.forEach((buildingEmployee) => {
    buildingStatus[buildingEmployee] = computeBuildingStatus(
      startDatetime,
      assignmentsByBuildingEmployee[buildingEmployee]
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Flex minWidth='max-content' alignItems='center' gap='2'>
          <Spacer />

          <Heading size='lg'>
            Building Report
          </Heading>

          <Spacer />

          <Checkbox
            isChecked={showFewerColumns}
            onChange={() => setShowFewerColumns(!showFewerColumns)}
            style={{ marginRight: '5px' }}
          >
            Hide details
          </Checkbox>
          <ButtonGroup variant='outline' spacing='6' isAttached>
            <Button onClick={shiftDateBackwards}>
              <AiFillLeftCircle size={24} />
            </Button>
            <Button onClick={resetDateToToday}>
              Today
            </Button>
            <Button onClick={shiftDateForwards}>
              <AiFillRightCircle size={24} />
            </Button>
          </ButtonGroup>

        </Flex>

        <Divider style={ {marginTop: 10, marginBottom: 10} } />
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table>
          <Thead>
            <Tr>
              <Th key="Building" maxW="100px">Building</Th>
              <Th key="Employee" maxW="100px">Cleaner</Th>
              <Th key="missedCount" maxW="100px">No show</Th>
              <Th key="missedClockOut" maxW="100px">No clock-out</Th>
              <Th key="totalTrackedCount" maxW="100px">Total</Th>
              <Th key="completedHours" maxW="100px">Completed hours</Th>
              <Th key="scheduledHours" maxW="100px">Scheduled hours</Th>
              {showFewerColumns ? null: (
                <>
                {Object.values(buildingStatus)[0].map((statusObj, index) => (
                  <Th key={`status_${index}`} maxW="100px">
                    {format(new Date(statusObj.date), "yyyy-MM-dd (iii)")}
                  </Th>
                ))}
                </>)}
            </Tr>
          </Thead>
          <Tbody>
            {Array.from(allBuildingEmployee).map((buildingEmployee) => {
              const shiftStatuses = buildingStatus[buildingEmployee];
              if (shiftStatuses.every((statusObj) => statusObj.status === "N/A")) {
                return null;
              }
              const { missedShifts, missedClockOut, notTrackedShifts, totalTrackedShifts } = countShifts(assignmentsByBuildingEmployee[buildingEmployee]);
              const { completedHours, scheduledHours } = countHours(assignmentsByBuildingEmployee[buildingEmployee]);
              const assignment = assignmentsByBuildingEmployee[buildingEmployee][0];
              return (
              <Tr key={buildingEmployee}>
                <Td key={`building_${buildingEmployee}`} minW="100px">{assignment.location.name}</Td>
                <Td key={`employee_${buildingEmployee}`} minW="100px">{assignment.janitor.name}</Td>
                <Td key={`missedCount${buildingEmployee}`} minW="100px">{missedShifts}</Td>
                <Td key={`missedClockOut${buildingEmployee}`} minW="100px">{missedClockOut}</Td>
                <Td key={`totalTrackedCount${buildingEmployee}`} minW="100px">{totalTrackedShifts}</Td>
                <Td key={`completedHours${buildingEmployee}`} minW="100px">{completedHours}</Td>
                <Td key={`scheduledHours${buildingEmployee}`} minW="100px">{scheduledHours}</Td>
                {showFewerColumns ? null: (
                  <>
                    {shiftStatuses.map((statusObj, index) => (
                      <Td key={`status_${buildingEmployee}_${index}`} minW="100px">{getStatusPill(statusObj.status)}</Td>
                    ))}
                  </>)}
              </Tr>);
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default BuildingReport;
