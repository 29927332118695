import useSWR, { mutate } from "swr";
import { useState } from "react";

import { Box, Button, Flex, Heading, Image, useTheme } from "@chakra-ui/react";

import { useAuth } from "@/contexts/auth_context";

import { BuildingList } from "@/components/BuildingList";
import { BuildingsByHealth, DaysInRedChart } from "@/components/Charts";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";


import {BuildingModal} from "@/components/BuildingModal";
import {modalAction} from "@/utils/modal_action";

import buildingRed from '../../assets/building_red.png';

import styles from "./styles.module.scss";

const BuildingDashboard = () => {
  const theme = useTheme();
  const [locationAction, setLocationAction] = useState(null);
  const [activeLocation, setActiveLocation] = useState(null);
  const [isEditBuilding, setIsEditBuilding] = useState(false);
  const authContext = useAuth();


  const LOCATIONS_METRICS_URL = `${import.meta.env.VITE_API_SERVER}/locations/metrics`;

  const { data: data, error: error, isLoading: isLoading } = useSWR(
    LOCATIONS_METRICS_URL,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  if (error || isLoading) {
    return <FullScreenSpinner />
  }

  const buildingsInRed = data.locations.filter(location =>
    location.healthScores.overall < 0.65
  )

  const onUpdate = () => {
    // TODO remove reloading the page and use mutate(LOCATIONS_METRICS_URL) - Need to fix building not showing with filters
      window.location.reload();
  }

  // If all buildings have 0 revenue, fallback to non revenue displays
  const revenueAvailable = data.locations.reduce(
    (sum, item) => sum + item.revenuePerMonth, 0
  ) > 0
  const revenueInRed = buildingsInRed.reduce(
    (sum, item) => sum + item.revenuePerMonth, 0
  )

  return (
    <Flex
      className={styles.container}
      flexDirection={"column"}
      height="100%"
    >
      <Flex
        className={styles.header}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Heading className={styles.text} size="lg">Buildings Health</Heading>
        <Button
          onClick={() => {
            setIsEditBuilding(true)
            setLocationAction(modalAction.CREATE)
          }}>Create New Building</Button>
      </Flex>
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <DaysInRedChart />
        <Box
          backgroundColor="#FFF2F2"
          borderRadius="3xl"
          border={`1px solid ${theme.colors.secondary.red}`}
          padding={"4em 0em 5em 0em"}
          width={"32%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Image src={buildingRed} boxSize="3.5em" />
          <Heading className={styles.text} color={theme.colors.secondary.red} fontSize={"5em"} margin={0}>
            {/* If no revenue available, display count of buildings in red */}
            {revenueAvailable ? `${(Math.round(revenueInRed / 1000)).toLocaleString(
              "en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 }
            )}k` : buildingsInRed.length}
          </Heading>
          <Heading className={styles.text} size="lg" margin={0}>{!revenueAvailable && "Buildings "}At Risk</Heading>
        </Box>
        <BuildingsByHealth />
      </Flex>
      <div className={styles.header}>
        <Heading className={styles.text} size="md">Buildings</Heading>
      </div>
      <BuildingList data={data} revenueAvailable={revenueAvailable} onEdit={(location) => {
        setActiveLocation(location)
        setLocationAction(modalAction.UPDATE)
        setIsEditBuilding(true)
      }} />
      {isEditBuilding && (
        <BuildingModal
          isOpen={isEditBuilding}
          onClose={(reload) => {
            setActiveLocation(null)
            setIsEditBuilding(false)

            if (reload) {
              onUpdate()
            }
          }}
          location={activeLocation}
          locationAction={locationAction}
        />)
      }
    </Flex >
  )
}

export default BuildingDashboard;
