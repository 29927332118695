import { useAuth } from "@contexts/auth_context";
import { Box, Button, useTheme, Heading, Flex, Text, useToast, FormLabel } from "@chakra-ui/react";
import { useState } from "react";
import { DateTimePicker } from "@/components/DateTimePicker";
import {addDays } from "date-fns";
import { useTimezone } from "@/contexts/timezone_context";

const Reports: React.FC = () => {
  const authContext = useAuth();
  const [startDatetime, setStartDatetime] = useState(new Date());
  const [endDatetime, setEndDatetime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const toast = useToast();
  const timezoneContext = useTimezone();

  const handleDownload = async () => {
    toast.closeAll();
    if (!startDatetime || !endDatetime) {
      toast({
        description: "Please select start and end dates",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return;
    }
    const startDateBeginningOfDay = timezoneContext.startOfDay(startDatetime);
    const endDateBeginningOfDay = timezoneContext.startOfDay(endDatetime);
    // Add one day to make end date inclusive
    const endDateBeginningOfDayPlusOne = addDays(endDateBeginningOfDay, 1);
    if (startDateBeginningOfDay >= endDateBeginningOfDayPlusOne) {
      toast({
        description: "Start date must be before end date",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);

    const organization = authContext.state.userToken?.organization;
    await authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/reports/relief?`
      + `organization_id=${encodeURIComponent(organization.id)}`
      + `&start_datetime=${encodeURIComponent(startDateBeginningOfDay.toISOString())}`
      + `&end_datetime=${encodeURIComponent(endDateBeginningOfDayPlusOne.toISOString())}`,
    {
      method: "get",
      headers: {
        'Accept': 'text/csv',
      },
    })
    .then(response => response.blob())
    .then((blob) => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `relief-report-${timezoneContext.formatDate(startDateBeginningOfDay, 'yyyy-MM-dd')}-${timezoneContext.formatDate(endDateBeginningOfDay, 'yyyy-MM-dd')}.csv`;
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
    })
    .catch((error) => console.error('Error downloading the file:', error));
    setIsLoading(false);
  };

  const handleVacuumReportDownload = async () => {
    toast.closeAll();
    if (!startDatetime || !endDatetime) {
      toast({
        description: "Please select start and end dates",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return;
    }
    const startDateBeginningOfDay = timezoneContext.startOfDay(startDatetime);
    const endDateBeginningOfDay = timezoneContext.startOfDay(endDatetime);
    // Add one day to make end date inclusive
    const endDateBeginningOfDayPlusOne = addDays(endDateBeginningOfDay, 1);
    if (startDateBeginningOfDay >= endDateBeginningOfDayPlusOne) {
      toast({
        description: "Start date must be before end date",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);

    const organization = authContext.state.userToken?.organization;
    await authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/reports/vacuum?`
      + `organization_id=${encodeURIComponent(organization.id)}`
      + `&start_datetime=${encodeURIComponent(startDateBeginningOfDay.toISOString())}`
      + `&end_datetime=${encodeURIComponent(endDateBeginningOfDayPlusOne.toISOString())}`,
      {
        method: "get",
        headers: {
          'Accept': 'text/csv',
        },
      })
      .then(response => response.blob())
      .then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `vacuum-report-${timezoneContext.formatDate(startDateBeginningOfDay, 'yyyy-MM-dd')}-${timezoneContext.formatDate(endDateBeginningOfDay, 'yyyy-MM-dd')}.csv`;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => console.error('Error downloading the file:', error));
    setIsLoading(false);
  };


  return (
    <>

      <Flex
        flex={1}
      >
        <Box
          borderRadius="3xl"
          backgroundColor="white"
          border={`1px solid ${theme.colors.gray[200]}`}
          display="flex"
          width={"100%"}
          flexDirection="column"
          margin={6}
        >
          <Heading
            as="h1"
            size="md"
            pl={6}
            pt={6}
            marginBottom={0}
          >
            <Text>Relief report</Text>
          </Heading>
          <Flex margin={6} justifyContent={"space-between"} alignContent={"center"}>
            <Flex>
              <Flex
                flexDirection={"column"}
                mr={3}
              >
                <FormLabel>Start date</FormLabel>
                <DateTimePicker
                  value={startDatetime}
                  onChange={setStartDatetime}
                  showTime={false}
                  showDate={true}
                />
              </Flex>

              <Flex
                flexDirection={"column"}
                ml={3}
              >
                <FormLabel>End date</FormLabel>
                <DateTimePicker
                  value={endDatetime}
                  onChange={setEndDatetime}
                  showTime={false}
                  showDate={true}
                />
              </Flex>
            </Flex>
            <Button
              isLoading={isLoading}
              alignSelf={"flex-end"}
              onClick={handleDownload}
            >
            Download
          </Button>
          </Flex>

        </Box>
      </Flex>

      <Flex
        flex={1}
      >
        <Box
          borderRadius="3xl"
          backgroundColor="white"
          border={`1px solid ${theme.colors.gray[200]}`}
          display="flex"
          width={"100%"}
          flexDirection="column"
          margin={6}
        >
          <Heading
            as="h1"
            size="md"
            pl={6}
            pt={6}
            marginBottom={0}
          >
            <Text>Vacuum Report</Text>
          </Heading>
          <Flex margin={6} justifyContent={"space-between"} alignContent={"center"}>
            <Flex>
              <Flex
                flexDirection={"column"}
                mr={3}
              >
                <FormLabel>Start date</FormLabel>
                <DateTimePicker
                  value={startDatetime}
                  onChange={setStartDatetime}
                  showTime={false}
                  showDate={true}
                />
              </Flex>

              <Flex
                flexDirection={"column"}
                ml={3}
              >
                <FormLabel>End date</FormLabel>
                <DateTimePicker
                  value={endDatetime}
                  onChange={setEndDatetime}
                  showTime={false}
                  showDate={true}
                />
              </Flex>
            </Flex>
            <Button
              isLoading={isLoading}
              alignSelf={"flex-end"}
              onClick={handleVacuumReportDownload}
            >
              Download
            </Button>
          </Flex>

        </Box>
      </Flex>
    </>

  );
};

export default Reports;
