import {Box, Flex, Heading, Text, useTheme, Table, Tbody, Thead, Tr, Th, Td, Button, useToast} from "@chakra-ui/react";
import { formatPhoneNumber, formatLatLong } from "@/utils/formatting";
import React, {useEffect, useRef, useState} from "react";
import { EmbeddedMap } from "@/components/EmbeddedMap";
import {modalAction} from "@/utils/modal_action";
import {BuildingModal} from "@/components/BuildingModal";
import {useAuth} from "@contexts/auth_context";
import {useNavigate} from "react-router-dom";
import useSWR, {mutate} from "swr";
import {build} from "vite";
import {FullScreenSpinner} from "@/components/FullScreenSpinner";
import {ErrorPage} from "@/components/ErrorPage";

function dayOfWeekAsString(dayIndex) {
  return [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"  // We include Sunday twice to allow for 0 and 1 indexed days of week
  ][dayIndex] || '';
}

/*
  TODO: we should decouple locationData from the data in building info. To do this we
  should rely on the useSWR call to GET /locations/:id but we need to include
  the schedule, users, and supervisor data in that endpoint as well. Everything else
  about the location comes from the call in this component
 */
const BuildingInfo = ({ locationData }) => {
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buildingAction, setBuildingAction] = useState(null);
  const authContext = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const mapRef = useRef(null);

  const LOCATION_URL = `${import.meta.env.VITE_API_SERVER}/locations/${locationData.location.id}`

  // This will reload the location from the API and force the map to re-center on the new latlng if it changed.
  const handleUpdate = () => {
    mutate(LOCATION_URL).then(() => {
      if (mapRef.current) {
        mapRef.current()
      }
    })
  }

  const { data, error, isLoading } = useSWR(
    LOCATION_URL,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error} />;
  }

  // handle API specific errors
  if (data.error) {
    return <ErrorPage errorMessage={data.error} />;
  }

  const sortLocationSchedule = (schedule) => {
    const entries = Object.entries(schedule).filter(([key, value]) => value !== undefined);

    const filteredEntries = entries.filter(([key, value]) => {
      const aKey = Object.keys(value)[0];
      return aKey !== undefined;
    });

    const sortedSchedule = filteredEntries.sort((a, b) => {
      const aKey = Object.keys(a[1])[0]; // Get the schedule label for entry a
      const bKey = Object.keys(b[1])[0]; // Get the schedule label for entry a
      return bKey.localeCompare(aKey); // This will sort the labels and put (Removed)... labels at the end
    });

    return sortedSchedule;
  }


  const sortedSchedules = sortLocationSchedule(locationData?.location?.schedule || {})

  const handleDelete = () :void => {
    authContext.authenticatedFetch(LOCATION_URL,
      {
        method: "delete"
      }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      navigate("/buildings/");
    }).catch((error) => {
      toast({
        description: error.message,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
    })
  }

  const renderSupervisors = () => {
    return (
      <div>
        {locationData.location.supervisors.length > 0 ? (
          locationData.location.supervisors.map((supervisor, index) => (
            <div key={index}>{locationData.users[supervisor].name}</div>
          ))
        ) : (
          '-'
        )}
      </div>
    );
  };

  const contactInfo = !!locationData.location?.contactInfo && {
    name: locationData.location.contactInfo?.name || "-",
    phoneNumber: formatPhoneNumber(locationData.location.contactInfo?.phoneNumber) || "-"
  }

  const buildingContactCard = !!contactInfo && (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.75em"}
      width={"100%"}
    >
      <Table variant="unstyled" size="sm">
        <Thead>
          <Tr borderBottom={`1px solid ${theme.colors.gray[200]}`}>
            <Th
              color={theme.colors.secondary.darkGray}
              paddingY="0.5em"
              verticalAlign="top"
              fontWeight="normal"
            >
              Name
            </Th>
            <Th
              color={theme.colors.secondary.darkGray}
              paddingY="0.5em"
              verticalAlign="top"
              fontWeight="normal"
            >
              Phone Number
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr
          >
            <Td
              padding="1.5em"
              verticalAlign="top"
            >
              {contactInfo.name}
            </Td>
            <Td
              padding="1.5em"
              verticalAlign="top"
            >
              {contactInfo.phoneNumber}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );

  const getEmployeeData = (cleaner: any, employeeSchedule: any) => ({
    name: cleaner.name,
    phoneNumber: formatPhoneNumber(cleaner.phoneNumber),
    schedule: Object.entries(employeeSchedule)
      .map(([range, days]: [string, number[]]) =>
        `${range} on ${days
          .sort((a: number, b: number) => a - b)
          .map((day: number) => dayOfWeekAsString(day).slice(0, 3)).join(", ")}`
      )
      .join("\n")
  });

  const assignedCleanersCard = locationData.location.schedule && (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.75em"}
      width={"100%"}
    >
      <Box>
        <Table variant="unstyled" size="sm">
          <Thead>
              <Tr borderBottom={`1px solid ${theme.colors.gray[200]}`}>
                <Th
                  color={theme.colors.secondary.darkGray} // Changed to light grey
                  paddingY="0.5em"
                  verticalAlign="top"
                  fontWeight="normal"
                >
                  Name
                </Th>
                <Th
                  color={theme.colors.secondary.darkGray} // Changed to light grey
                  paddingY="0.5em"
                  verticalAlign="top"
                  fontWeight="normal"
                >
                  Phone Number
                </Th>
                <Th
                  color={theme.colors.secondary.darkGray} // Changed to light grey
                  paddingY="0.5em"
                  verticalAlign="top"
                  fontWeight="normal"
                >
                  Schedule
                </Th>
              </Tr>
            </Thead>
            <Tbody>
            {sortedSchedules.length > 0 ? (
              sortedSchedules.map(([employeeId, employeeSchedule], index) => {
                const cleaner = locationData.users[employeeId];
                const employeeData = getEmployeeData(cleaner, employeeSchedule);
                const isLastItem = index === Object.entries(locationData.location.schedule).length - 1;

                return (
                  <Tr
                    key={employeeId}
                    borderBottom={isLastItem ? "none" : `1px solid ${theme.colors.gray[200]}`}
                    backgroundColor={employeeData.schedule.includes("Removed") ? theme.colors.gray[100] : "transparent"}
                  >
                    <Td
                      padding="1.5em"
                      verticalAlign="top"
                    >
                      {employeeData.name}
                    </Td>
                    <Td
                      padding="1.5em"
                      verticalAlign="top"
                    >
                      {employeeData.phoneNumber}
                    </Td>
                    <Td
                      padding="1.5em"
                      verticalAlign="top"
                    >
                      {employeeData.schedule}
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <Tr>
                <Td>-</Td>
                <Td>-</Td>
                <Td>-</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );

  return (
    <Flex flexDirection="column" pt={"1em"}>
      <Flex flexDirection="column" pt={"1em"}>
        <Flex justifyContent="space-between" alignItems="center" mb="1.5em">
          <Heading
                          size={"md"}
                          fontWeight={"medium"}
                          marginBottom={"0.5em"}
                          marginTop={"1.5em"}
                        >
                          <Text fontWeight={"medium"}>Building Information</Text>
                      </Heading>
            <Box
              marginRight={"1em"}>
              <Button
                mr={"1em"}
                onClick={() => {
                  setBuildingAction(modalAction.UPDATE);
                  setIsModalOpen(true);
                }}>
                Edit Building
              </Button>
              <Button
                variant='solid'
                bg={"transparent"}
                _hover={{ bg: "transparent" }}
                color='red.500'
                onClick={() => {
                  setBuildingAction(modalAction.DELETE);
                  setIsModalOpen(true);
                }}>
                Delete Building
              </Button>
            </Box>
        </Flex>
        <Box
          borderRadius="3xl"
          backgroundColor="white"
          border={`1px solid ${theme.colors.gray[200]}`}
          padding={"1.75em"}
          width={"100%"}
        >
          <Table variant="unstyled">
            <Thead>
              <Tr>
                <Th
                  padding="0.75em"
                  verticalAlign="top"
                  color={theme.colors.secondary.darkGray}
                  fontWeight="normal"
                  width="25%"
                >
                  Name
                </Th>
                <Th
                  padding="0.75em"
                  verticalAlign="top"
                  color={theme.colors.secondary.darkGray}
                  fontWeight="normal"
                  width="25%"
                >
                  Supervisor
                </Th>
                <Th
                  padding="0.75em"
                  verticalAlign="top"
                  color={theme.colors.secondary.darkGray}
                  fontWeight="normal"
                  width="50%"
                >
                  Address
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td
                  padding="0.75em"
                  verticalAlign="top"
                >
                  {data.location.name}
                  {data.location.locationCode && (<Box>{data.location.locationCode}</Box>)}
                </Td>
                <Td
                  padding="0.75em"
                  verticalAlign="top"
                >
                  {renderSupervisors()}
                </Td>
                <Td
                  padding="0.75em"
                  verticalAlign="top"
                >
                  {data.location.address}
                  {data.location.latitude && data.location.longitude && !isModalOpen && (
                    <Box width="100%" height="250px" borderRadius="3xl" overflowY="auto">
                      <EmbeddedMap
                          icon={{
                            url: '/brightgo-marker-circle.svg',
                            scaledSize: { width: 16, height: 16 },
                            anchor: { x: 8, y: 8 }
                          }}
                          showGeofence={true}
                          allowCenterDrag={false}
                          geofenceRadius={data.location.geofenceRadius}
                          latLng={formatLatLong(data.location.latitude, data.location.longitude)}
                          setRecenterRef={(callback: any) :void => (mapRef.current = callback)}
                        />
                    </Box>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Heading
                        size={"md"}
                        fontWeight={"medium"}
                        marginBottom={"0.5em"}
                        marginTop={"1.5em"}
                      >
                        <Text fontWeight={"medium"}>Building Contacts</Text>
                    </Heading>
        {buildingContactCard}
        <Heading
                        size={"md"}
                        fontWeight={"medium"}
                        marginBottom={"0.5em"}
                        marginTop={"1.5em"}
                      >
                        <Text fontWeight={"medium"}>Cleaners</Text>
                    </Heading>
        {assignedCleanersCard}
      </Flex>
      {isModalOpen && (<BuildingModal
          isOpen={isModalOpen}
          onClose={(reload: boolean) => {
            setIsModalOpen(false)
            setBuildingAction(null);

            if (reload) {
              handleUpdate()
            }
          }}
          location={data.location}
          handleDelete={handleDelete}
          locationAction={buildingAction}
        />)}
    </Flex>
  );
}

export default BuildingInfo;
