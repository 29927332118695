import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { addDays, subDays } from "date-fns";
import { Spinner } from '@chakra-ui/react';
import { useAuth } from '@/contexts/auth_context';
import { ClockInEmployeeCard } from '../ClockInEmployeeCard';
import { useTimezone } from '@/contexts/timezone_context';

import styles from "./styles.module.scss";

const ShiftHistory = ({ userId }: { userId: number }) => {
    const authContext = useAuth();
    const timezoneContext = useTimezone();

    const getUrl = () => {
        const today = timezoneContext.startOfDay(new Date())
        const startDatetime = subDays(today, 14).toISOString();
        const endDatetime = addDays(today, 1).toISOString();

        return (
            `${import.meta.env.VITE_API_SERVER}/calendar_events` +
            `?start_datetime=${encodeURIComponent(startDatetime)}` +
            `&end_datetime=${encodeURIComponent(endDatetime)}` +
            `&cleaner_ids[]=${userId}`
        );
    }

    const fetchClockInsForTeam = async (url: String) => {
        const response = await authContext.authenticatedFetch(url);
        const data = response.json();

        return data
    }

    const { data, error, isLoading } = useSWR(
        getUrl(),
        url => fetchClockInsForTeam(url)
    )

    if (isLoading) {
        return (
            <div className={styles.container}>
                <div className={styles.loadingState}>
                    <Spinner size='xl' color="purple.500" />
                </div>
            </div>
        )
    }

    if (error || data?.error) {
        return (
            <div></div>
        )
    }

    const eventsForUser = Object.values(data.calendarEvents)
    .filter((event) => event.assignedUserId === userId)
    .sort((a, b) => new Date(b.scheduledStartDatetime).getTime() - new Date(a.scheduledStartDatetime).getTime());

    const { calendarEvents, users, locations } = data;
    return (
        <div className={styles.container}>
            <ClockInEmployeeCard
                employee={users[userId]}
                events={eventsForUser}
                locations={locations}
            />
        </div>
    )
}

export default ShiftHistory;