import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box, Button, Flex, Text, Table, Thead, Tbody, Th, Tr, Td, useTheme, Select } from "@chakra-ui/react";

import { EventFilter } from "@/components/EventFilter";
import { InspectionScore } from "@/components/InspectionScore";

import styles from "./styles.module.scss";
import {BsPencil} from "react-icons/bs";

const BuildingList = ({ data, revenueAvailable, onEdit }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  // Filtering
  const [searchParams, setSearchParams] = useSearchParams();
  const [buildingFilteredLocationIds, setBuildingFilteredLocationIds] = useState<Set<string>>(new Set());
  const [supervisorFilteredLocationIds, setSupervisorFilteredLocationIds] = useState<Set<string>>(new Set());
  const [healthFilteredLocationIds, setHealthFilteredLocationIds] = useState<Set<string>>(new Set());
  const [resetKey, setResetKey] = useState<boolean>(false);
  // Sorting
  const [sorting, setSorting] = useState<string>("building_name");
  const sortingOptions = {
    "building_name": {
      label: "Building Name",
      sortFunction: (a, b) => a.name.localeCompare(b.name)
    },
    "highest_score": {
      label: "Highest score",
      sortFunction: (a, b) => (b.healthScores?.overall || -1) - (a.healthScores?.overall || -1)
    },
    "lowest_score": {
      label: "Lowest score",
      sortFunction: (a, b) => (a.healthScores?.overall || -1) - (b.healthScores?.overall || -1)
    },
  }
  if (revenueAvailable) {
    sortingOptions["revenue"] = {
      label: "Revenue",
      sortFunction: (a, b) => (b.revenuePerMonth || -1) - (a.revenuePerMonth || -1)
    }
  }

  // Unique identifier for each task
  const getLocationId = (location) => {
    return location.id;
  };

  const locationList = Object.values(data.locations)

  const filteredLocations = locationList.filter(location =>
    buildingFilteredLocationIds.has(location.id) &&
    supervisorFilteredLocationIds.has(location.id) &&
    healthFilteredLocationIds.has(location.id)
  )

  const sortedLocations = !filteredLocations ?
    [] :
    filteredLocations.sort(function (a, b) {
      return sortingOptions[sorting].sortFunction(a, b)
    })

  const locationIdSetsFromOtherFilters = (locationIdSetForCurrentFilter) => {
    return [buildingFilteredLocationIds, supervisorFilteredLocationIds, healthFilteredLocationIds]
      .filter((locationIdSet) => {
        return locationIdSet != locationIdSetForCurrentFilter;
      });
  }

  const rerenderFilters = () => {
    // hide all events before rerendering to avoid flickering.
    setBuildingFilteredLocationIds(new Set());
    setSupervisorFilteredLocationIds(new Set());
    setHealthFilteredLocationIds(new Set());
    setResetKey(!resetKey);
  }

  return (
    <Box
      borderRadius="3xl"
      overflowY="hidden"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      display="flex"
      flexDirection="column"
      minHeight="35em"
    >
      <Flex
        className={styles.filterContainer}
        alignItems="center"
        justifyContent='space-between'
        marginTop={"1.5em"}
        padding={"0em 2em"}
      >
        <Flex alignItems={"center"}>
          <Text fontSize='lg' marginBottom={0} marginRight={"1em"}>Filter by:</Text>
          <Box mr={6}>
            <EventFilter
              key={"Building" + resetKey}
              events={locationList}
              eventIdSetsFromOtherFilters={locationIdSetsFromOtherFilters(buildingFilteredLocationIds)}
              getFilterKey={(location) => location.name}
              getEventId={getLocationId}
              convertFilterKeyToLabel={(filterKey) => filterKey}
              updateEvent={setBuildingFilteredLocationIds}
              filterName={"Building"}
              width={160}
            />
          </Box>
          <Box mr={6}>
            <EventFilter
              key={"Supervisor" + resetKey}
              events={locationList}
              eventIdSetsFromOtherFilters={locationIdSetsFromOtherFilters(supervisorFilteredLocationIds)}
              getFilterKey={(location) => {
                return !location.supervisors?.length
                  ? "No Supervisor"
                  : data.users[location.supervisors[0]].name
              }}
              getEventId={getLocationId}
              convertFilterKeyToLabel={(filterKey) => filterKey}
              updateEvent={setSupervisorFilteredLocationIds}
              filterName={"Supervisor"}
              width={160}
            />
          </Box>
          <Box mr={6}>
            <EventFilter
              key={"Health" + resetKey}
              events={locationList}
              eventIdSetsFromOtherFilters={locationIdSetsFromOtherFilters(healthFilteredLocationIds)}
              getFilterKey={(location) => {
                let score = Math.round(location.healthScores.overall * 100)
                switch (true) {
                  case (score < 65):
                    return "At Risk"
                  case (score < 80):
                    return "Fair";
                  case (score <= 100):
                    return "Healthy"
                  default:
                    return "N/A"
                }
              }}
              getEventId={getLocationId}
              convertFilterKeyToLabel={(filterKey) => filterKey}
              updateEvent={setHealthFilteredLocationIds}
              filterName={"Health"}
              width={160}
            />
          </Box>
          <Button
            variant={"ghost"}
            onClick={() => {
              setSearchParams('');
              rerenderFilters();
            }}
          >
            Clear all
          </Button>
        </Flex>
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
        >
          <Text fontSize={14} mr={4} marginBottom={0} marginRight={"1em"}>Sort by: </Text>
          <Select
            width={60}
            size={"sm"}
            py={1}
            defaultValue={sorting}
            borderRadius={4}
            iconSize={14}
            focusBorderColor="primary.500"
            onChange={(event) => setSorting(event.target.value)}
          >
            {Object.keys(sortingOptions).map((key) => (
              <option key={key} value={key}>{sortingOptions[key].label}</option>
            ))}
          </Select>
        </Flex>
      </Flex>
      <Flex
        my={6}
        flex={1}
        flexDirection="column"
        overflowY="hidden"
      >
        <Flex className={styles.tableHeaderContainer}>
          <Table variant="simple" colorScheme="gray">
            <Thead position="sticky" top={0} bg="white">
              <Tr>
                <Th width="30%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={6} >Building</Th>
                <Th width="20%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Supervisor</Th>
                <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Health Score</Th>
                <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Last Inspection Score</Th>
                <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Average Attendance</Th>
                <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Issue Count</Th>
                {revenueAvailable &&
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Revenue per Month</Th>
                }
                <Th pl={0}> </Th>
              </Tr>
            </Thead>
          </Table>
        </Flex>
        <Flex
          overflowY="scroll"
        >
          <Table variant="simple" colorScheme="gray">
            <Tbody>
              {sortedLocations.map((location) => (
                <Tr width="100%"
                  key={location.id}
                  onClick={() => window.open(`/buildings/${location.id}`, "_blank")}
                  style={{ cursor: 'pointer' }}
                  _hover={{ bg: 'gray.50' }}
                >
                  <Td width="30%" pl={6} >{location.name}</Td>
                  <Td width="20%" pl={0} >
                    {!location.supervisors?.length
                      ? "-"
                      : data.users[location.supervisors[0]].name}
                  </Td>
                  <Td width="10%" pl={0} >
                    <InspectionScore
                      circleSize={32}
                      textSize={14}
                      score={location.healthScores.overall}
                    />
                  </Td>
                  <Td width="10%" pl={0} >
                    {location.inspectionScore ? Math.round(100 * location.inspectionScore) : "-"}
                  </Td>
                  <Td width="10%" pl={0} >
                    {location.attendancePercentage.toLocaleString(
                      undefined, { style: 'percent', minimumFractionDigits: 0 }
                    )}
                  </Td>
                  <Td width="10%" pl={0} >{location.issueCount}</Td>
                  {revenueAvailable &&
                    <Td width="10%" pl={0} >{
                      location.revenuePerMonth ?
                        location.revenuePerMonth.toLocaleString(
                          "en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 }
                        )
                        : "-"}</Td>
                  }
                  <Td p={0} >
                    <Box
                      width="100%"
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      padding="20px"
                      onClick={(e) => {
                        onEdit(location)
                        e.stopPropagation()
                      }}
                    >
                      <BsPencil />
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </Box>
  )
}

export default BuildingList;
