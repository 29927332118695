import {
    Flex,
    Text,
    Divider,
    Spacer,
    useTheme,
    Stat,
    StatLabel,
    StatNumber,
    Heading,
  } from "@chakra-ui/react";
import { HiOutlinePhone } from "react-icons/hi";
import { MdFastfood } from "react-icons/md";
import styles from "./styles.module.scss";
import {
  addSeconds,
  differenceInMinutes,
} from "date-fns";
import { useState } from "react";
import { ShiftStatus } from "../ShiftStatus";
import { Employee } from "@/types";
import { useAuth } from "@contexts/auth_context";
import { BiEdit } from "react-icons/bi";
import { PunchEditModal } from "@/components/PunchEditModal";
import { formatDuration } from "@/utils/formatting";
import { useTimezone } from "@/contexts/timezone_context";

const ClockInEmployeeCard = ({
                              employee,
                              locations,
                              events,
                              refreshData,
                            }: {
                              employee: Employee,
                              locations: Record<string, Location>,
                              events: Array<any>,
                              showEditButton: boolean,
                              refreshData: () => void,
                            }) => {

  const theme = useTheme();
  const authContext = useAuth();
  const timezoneContext = useTimezone();
  const showEditButton = authContext.state.features?.punchCorrection || false
  const [shiftToBeEditted, setShiftToBeEditted] = useState(null);

  const MealBreakInfo = ({shift}) => {
    if (!!shift && !!shift.lunchMinutes) {
      return <div className={styles.lunchBreak}>
        <Flex alignItems="center">
          <MdFastfood size={12} color={theme.colors.blue["300"]} style={{marginLeft: 2, marginRight: 2}} />
          <div>
            Lunch: {shift.lunchMinutes} minutes
          </div>
        </Flex>
      </div>;
    } else {
      return null;
    }
  };

  const getShiftByAssignmentId = async (event) => {
    try {
      const response = await authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/shifts/get_or_create?&assignment_id=${event.assignmentId}&calendar_start_datetime=${event.scheduledStartDatetime}&assignedUserId=${event.assignedUserId}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result.shift;
    } catch (error) {
      alert(error);
    }
  }

  const openShiftPage = async (event) => {
    if (event?.shifts[0]?.shiftId) {
      window.open(`/shift/${event.shifts[0].shiftId}`, "_blank");
      return;
    }
    const shift = await getShiftByAssignmentId(event);
    if (!shift?.id) return;
    window.open(`/shift/${shift.id}`, "_blank");
  }

  const fetchShiftAndOpenEditModal = async (event) => {
    const shift = await getShiftByAssignmentId(event);
    setShiftToBeEditted(shift);
  }

  const renderShiftsForEmployee = (events) => {

    if (events === null || events.length === 0) {
      return <div className={styles.eventDetails}>
        <Text fontSize={'md'}>No Shifts Scheduled</Text>
      </div>
    }

    return events.map((event, idx) => {
      const shift = event.shifts[0];
      const durationWithoutBreakInSeconds = shift?.duration ? shift?.duration - ((shift?.lunchMinutes || 0) * 60) - ((shift?.breakMinutes || 0) * 60) : null;
      return (
      <Flex
        flexDirection={"column"}
        key={`${event.assignmentId}-${event.scheduledStartDatetime}-${idx}`}
        className={styles.eventDetails}
        onClick={() => openShiftPage(event)}
        cursor={"pointer"}
        padding={4}
        borderRadius={8}
        _hover={{ bg: 'gray.50' }}
      >
        <div style={{marginBottom: 20}}>
          <Flex>
            <div>
              <Flex alignItems="center">
                <Text fontSize='md'>{locations[event.locationId].name}</Text>

                <div style={{marginLeft: 10}}>
                  <a href={locations[event.locationId].mapUrl} target="_blank" rel="noopener noreferrer">
                    <Text fontSize="xs" color={theme.colors.blackAlpha["600"]}>
                      {locations[event.locationId].address}
                    </Text>
                  </a>
                  <Text fontSize='xs' color={theme.colors.blackAlpha["600"]}>
                    {timezoneContext.formatDate(event.scheduledStartDatetime, "MMM dd, h:mm aaa") } - {timezoneContext.formatDate(addSeconds(new Date(event.scheduledStartDatetime), event.duration), "MMM dd, h:mm aaa") }, {(event.duration / 60 / 60).toFixed(2)} hrs
                  </Text>
                </div>
              </Flex>

            </div>

            <Spacer />

            <Flex flexDirection={'column'} alignItems={'flex-end'}>
              <Flex alignItems="center">
                <ShiftStatus eventStatus={event.status} />
                {showEditButton &&
                  <BiEdit
                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchShiftAndOpenEditModal(event);
                    }}
                  />
                }
              </Flex>
              <MealBreakInfo shift={shift} />
            </Flex>

          </Flex>

        </div>

        <div>
          <Flex>
            <Stat style={{minWidth: 130}}>
              <StatLabel>Start</StatLabel>
              <StatNumber style={{fontSize: 14}}>{timezoneContext.formatDate(event.scheduledStartDatetime, "MMM dd, h:mm aaa") }</StatNumber>
            </Stat>

            <Stat style={{marginLeft: 10, minWidth: 130}}>
              <StatLabel>Clocked In</StatLabel>
              { shift && shift.clockInTimestamp ?
                <StatNumber
                  style={{
                    fontSize: 14,
                    color: differenceInMinutes(new Date(shift.clockInTimestamp), new Date(event.scheduledStartDatetime)) >= 15 ? theme.colors.red["400"] : "",
                }}
                >
                  {timezoneContext.formatDate(new Date(shift.clockInTimestamp), "MMM dd, h:mm aaa") }
                </StatNumber>
                :
                <StatNumber style={{fontSize: 14}}>-</StatNumber>
              }
            </Stat>

            <Stat style={{marginLeft: 10, minWidth: 130}}>
              <StatLabel>End</StatLabel>
              <StatNumber style={{fontSize: 14}}>{timezoneContext.formatDate(addSeconds(new Date(event.scheduledStartDatetime), event.duration), "MMM dd, h:mm aaa") }</StatNumber>
            </Stat>

            <Stat style={{marginLeft: 10, minWidth: 130}}>
              <StatLabel>Clocked Out</StatLabel>
              { shift && shift.clockOutTimestamp ?
                <StatNumber style={{fontSize: 14}}>{timezoneContext.formatDate(new Date(shift.clockOutTimestamp), "MMM dd, h:mm aaa") }</StatNumber>
                : <StatNumber style={{fontSize: 14}}>-</StatNumber>
              }
            </Stat>

            <Stat style={{marginLeft: 10, minWidth: 130}}>
              <StatLabel>Duration</StatLabel>
              { shift && shift.clockOutTimestamp ?
                <StatNumber style={{
                  fontSize: 14,
                  color: differenceInMinutes(new Date(shift.clockOutTimestamp), new Date(shift.clockInTimestamp)) <= (event.duration / 60) ? theme.colors.red["400"] : "",
                }}>
                  { formatDuration(durationWithoutBreakInSeconds) }
                </StatNumber>
                : <StatNumber style={{fontSize: 14}}>-</StatNumber>
              }
            </Stat>
          </Flex>
        </div>
      </Flex>)
    })
  }

  return <div>
    <Heading size='sm' textTransform='uppercase'>
      <Flex alignItems="center" mx={4} mb={2}>
        {employee.name}
        <HiOutlinePhone size={14} color={theme.colors.blackAlpha["600"]} style={{marginLeft: 10, marginRight: 2}} />
        <Text fontSize='sm' color={theme.colors.blackAlpha["600"]}>{employee.phoneNumber}</Text>
      </Flex>
    </Heading>
    <Divider />
    {
      renderShiftsForEmployee(events)
    }
    <PunchEditModal
      isOpen={shiftToBeEditted !== null}
      onClose={() => setShiftToBeEditted(null)}
      shift={shiftToBeEditted}
      onSave={() => {
        setShiftToBeEditted(null);
        refreshData();
      }}
    />
  </div>
  }

export default ClockInEmployeeCard;
